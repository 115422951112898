import React from 'react'
import { IconContext } from "react-icons";
import { FaMapMarkerAlt, FaPhoneAlt, FaWrench, FaPencilAlt } from 'react-icons/fa'
import "../styles/tarjetaContacto.scss"

const TarjetaContacto = (props) => {

    const icono = props.icono;

    return (
        <div className="tarjeta-contacto">
            <IconContext.Provider value={{ color: "#1f1f1f", size: "5rem" }}>
                {(() => {
                    switch (icono) {
                        case "contacto":
                            return (
                                <div className="cuerpo-tarjeta">
                                    <FaPencilAlt />
                                    <h4>Correo</h4>
                                    <p>Nuestro e-mail de contacto, para cualquier pregunta que tengas.</p>
                                    <a href="mailto:hola@bullhost.es">hola@bullhost.es</a>
                                </div>
                            )
                        case "soporte":
                            return (
                                <div className="cuerpo-tarjeta">
                                    <FaWrench />
                                    <h4>Soporte</h4>
                                    <p>¿Tienes algún problema? Contacta con nuestro departamento de soporte</p>
                                    <a href="mailto:soporte@bullhost.es">soporte@bullhost.es</a>
                                </div>
                            )
                        case "telefono":
                            return (
                                <div className="cuerpo-tarjeta">
                                    <FaPhoneAlt />
                                    <h4>Teléfono</h4>
                                    <p>En nuestro teléfono resolveremos tus dudas o problemas.</p>
                                    <a href="tel:+34946941377">946 94 13 77</a>
                                </div>
                            )
                        case "leioa":
                            return (
                                <div className="cuerpo-tarjeta">
                                    <FaMapMarkerAlt />
                                    <h4>Oficina Bizkaia</h4>
                                    <p>Paseo Landabarri 2 (Edificio Cosimet), 48940 Leioa (Bizkaia).</p>
                                    <a target="_blank" rel="noopener, noreferrer" href="https://www.google.es/maps/place/Bullhost+Cloud+Services/@43.3307444,-2.9976396,17z/data=!3m1!4b1!4m5!3m4!1s0xd4e5bb51b392611:0x8d3dfea397dcf9be!8m2!3d43.3307405!4d-2.9954509">Cómo llegar</a>
                                </div>
                            )
                        case "girona":
                            return (
                                <div className="cuerpo-tarjeta">
                                    <FaMapMarkerAlt />
                                    <h4>Oficina Girona</h4>
                                    <p>Travessera de mar 1, 17770 Borrassà (Girona).</p>
                                    <a target="_blank" rel="noopener, noreferrer" href="https://www.google.com/maps/place/Sistemes+Figueres,+Travessera+de+Mar,+1,+17770+Borrassà,+Girona/@42.2223054,2.9303063,15z/data=!4m2!3m1!1s0x12ba930a8776009f:0x1e16acc934b7d2e">Cómo llegar</a>
                                </div>
                            )
                        default:
                            break;
                    }
                })()}
            </IconContext.Provider>
        </div>
    )
}


export default TarjetaContacto