import React, { useState } from "react"
import Layout from "../components/layout"
import Seo from "../components/SEO/SEO"
import HeaderPaginas from "../components/headerPaginas"
import TarjetaContacto from "../components/tarjetaContacto"
import "../styles/contacto.scss"
import TextareaAutosize from "react-textarea-autosize"
import { IconContext } from "react-icons"
import { FaTwitter, FaLinkedin } from "react-icons/fa"

import { useGoogleReCaptcha } from "react-google-recaptcha-v3"
import { Link } from "gatsby"

const Contacto = props => {
  const [nombre, setNombre] = useState("")
  const [empresa, setEmpresa] = useState("")
  const [email, setEmail] = useState("")
  const [telefono, setTelefono] = useState("")
  const [mensaje, setMensaje] = useState("")
  const [isChecked, setIsChecked] = useState(false)
  const [mensajeError, setMensajeError] = useState("")
  const [respuesta, setRespuesta] = useState("")

  const { executeRecaptcha } = useGoogleReCaptcha()

  const handleSubmit = async e => {
    e.preventDefault()
    // Check if the captcha was skipped or not
    if (!executeRecaptcha) {
      return
    }

    // This is the same as grecaptcha.execute on traditional html script tags
    const result = await executeRecaptcha("contacto")
    //--> grab the generated token by the reCAPTCHA
    // Prepare the data for the server, specifically body-parser

    const data = JSON.stringify({
      nombre,
      empresa,
      email,
      telefono,
      mensaje,
      result,
    })
    // POST request to your server

    fetch(`${process.env.API_URL}/contactosecurities`, {
      method: "POST",
      headers: {
        Accept: "application/json, text/plain, */*",
        "Content-type": "application/json",
      },
      body: data, //--> this contains your data
    })
      .then(res => res.json())
      .then(data => {
        const success = data.success
        const score = data.score

        if (success && score >= 0.7) {
          setMensajeError("Gracias por ponerte en contacto con nosotros")
          setRespuesta("correcto")
          setNombre("")
          setEmpresa("")
          setEmail("")
          setTelefono("")
          setMensaje("")
          setIsChecked(false)
        } else {
          setMensajeError(
            "Ha habido un error en el envío del formulario, por favor inténtalo de nuevo"
          )
          setRespuesta("error")
        }
      })
  }

  return (
    <div>
      <Layout>
        <Seo pathname={props.location.pathname} />
        <HeaderPaginas
          titulo="Contacto"
          imagen="/img/cabeceras/BullHost_contacto_home.jpg"
        />
        <div className="tarjetas-contacto limitador">
          <TarjetaContacto icono="contacto" />
          <TarjetaContacto icono="soporte" />
          <TarjetaContacto icono="telefono" />
          <TarjetaContacto icono="leioa" />
          <TarjetaContacto icono="girona" />
        </div>
        <div className="iconos-sociales bloque-acento">
          <div className="overlay-sociales"></div>
          <div className="limitador">
            <h3>Síguenos en nuestras redes sociales</h3>
            <ul>
              <IconContext.Provider value={{ color: "#1f1f1f", size: "3rem" }}>
                <li>
                  <a
                    href="https://twitter.com/Bull_Host/"
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    <FaTwitter />
                  </a>
                </li>
                <li>
                  <a
                    href="https://es.linkedin.com/company/bullhost-cloud-services"
                    rel="noreferrer noopener"
                    target="_blank"
                  >
                    <FaLinkedin />
                  </a>
                </li>
              </IconContext.Provider>
            </ul>
          </div>
        </div>
        <div className="cuerpo-contacto limitador" id="formulario-contacto">
          <div className="caja-formulario">
            <h3>¿Hablamos?</h3>
            <form
              className="form-contacto"
              method="post"
              onSubmit={handleSubmit}
            >
              <label htmlFor="nombre">Nombre</label>
              <input
                name="nombre"
                placeholder="Nombre*"
                required
                value={nombre}
                onChange={e => setNombre(e.target.value)}
              />

              <label htmlFor="empresa">Empresa</label>
              <input
                name="empresa"
                placeholder="Empresa*"
                required
                value={empresa}
                onChange={e => setEmpresa(e.target.value)}
              />
              <label htmlFor="email">Email</label>
              <input
                type="email"
                name="email"
                placeholder="Email*"
                required
                value={email}
                onChange={e => setEmail(e.target.value)}
              />

              <label htmlFor="telefono">Teléfono</label>
              <input
                type="tel"
                name="telefono"
                placeholder="Teléfono*"
                maxLength="14"
                minLength="9"
                required
                value={telefono}
                onChange={e => setTelefono(e.target.value)}
              />
              <label htmlFor="mensaje">Mensaje</label>
              <TextareaAutosize
                required
                name="mensaje"
                id="mensaje"
                height="300"
                placeholder="Escribe aquí tu mensaje..."
                value={mensaje}
                onChange={e => setMensaje(e.target.value)}
              />
              <div className="caja-checkbox">
                <input
                  type="checkbox"
                  id="privacidad"
                  name="privacidad"
                  // defaultChecked={isChecked}
                  checked={isChecked}
                  onChange={() => setIsChecked(!isChecked)}
                />
                {/* <label htmlFor="privacidad" className="check-box">
                                </label> */}
                <label htmlFor="privacidad" className="label-check">
                  He leído y acepto la{" "}
                  <Link to="/politica-de-privacidad/">
                    política de privacidad
                  </Link>
                </label>
              </div>

              <div
                className={`mensaje-formulario
                                ${respuesta}                              
                            `}
                dangerouslySetInnerHTML={{ __html: mensajeError }}
              />

              <IconContext.Provider value={{ color: "#1f1f1f" }}>
                <input
                  type="submit"
                  value="Enviar"
                  className="boton-enviar"
                  disabled={!isChecked}
                />
              </IconContext.Provider>
              <p>
                <small className="texto-recaptcha">
                  Este sitio está protegido por reCAPTCHA y se aplican la{" "}
                  <a href="https://policies.google.com/privacy">
                    política de privacidad
                  </a>{" "}
                  y los{" "}
                  <a href="https://policies.google.com/terms">
                    términos del servicio
                  </a>{" "}
                  de Google.
                </small>
              </p>
            </form>
          </div>
        </div>
      </Layout>
    </div>
  )
}

export default Contacto
